import * as React from 'react';
import ImageListItem from '@mui/material/ImageListItem';
import { Grid, ImageList, Typography } from '@mui/material';
import OutlinedButton from './OutlinedButton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch } from 'react-redux';
import { deletePhoto } from '../store/actions';

const TitlebarBelowImageList = ({ photos }) => {

  const theme = useTheme();
  const downThanMd = useMediaQuery(theme.breakpoints.down('md'));
  const downThanLg = useMediaQuery(theme.breakpoints.down('lg'));

  const dispatch = useDispatch();

  const handleDelete = async(id)=>{
    await dispatch(deletePhoto(id));
  }
  
  return (
    <ImageList gap={10} cols={downThanLg? (downThanMd? 1: 2 ): 3 }>
      {photos.map((photo) => (
        <ImageListItem key={photo.id}>
          <img
            src={`${photo.photoUrl}?w=248&fit=crop&auto=format`}
            srcSet={`${photo.photoUrl}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={photo.title}
            loading="lazy"
            style={{ height: '30vh' }}
          />
          <Grid container sx={{ p: 2 }} alignItems="center">
            <Grid item xs={8} align="left">
              <Typography variant="body2" fontWeight={200}>
                {photo.title}
              </Typography>
            </Grid>
            <Grid item xs={4} align="right">
              <OutlinedButton text="delete" onClick={() => {handleDelete(photo.id)}} size="small"></OutlinedButton>
            </Grid>
          </Grid>
        </ImageListItem>
      ))}
    </ImageList>

  );
}

export default TitlebarBelowImageList;
