import * as React from 'react';
import ImageListItem from '@mui/material/ImageListItem';
import { Grid, ImageList, Typography } from '@mui/material';
import OutlinedButton from './OutlinedButton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch } from 'react-redux';
import { deleteVideo } from '../store/actions';

const VideoList = ({ videos }) => {

    const theme = useTheme();
    const downThanMd = useMediaQuery(theme.breakpoints.down('md'));
    const downThanLg = useMediaQuery(theme.breakpoints.down('lg'));

    const dispatch = useDispatch();
    const handleDelete = async(id)=>{
        await dispatch(deleteVideo(id));
    }

    return (
        <ImageList gap={10} cols={downThanLg ? (downThanMd ? 1 : 2) : 3}>
            {videos.map((video) => (
                <ImageListItem key={video.id}>
                    {/* poster={item.poster}  */} 
                    <video controls  key={video.id} style={{ height: '30vh', width:'100%'}}>  
                        <source src={video.videoUrl} type="video/mp4" />
                    </video>
                    <Grid container sx={{ p: 2 }} alignItems="center">
                        <Grid item xs={8} align="left">
                            <Typography variant="body2" fontWeight={200}>
                                {video.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} align="right">
                            <OutlinedButton text="delete" onClick={() => {handleDelete(video.id)}} size="small"></OutlinedButton>
                        </Grid>
                    </Grid>
                </ImageListItem>
            ))}
        </ImageList>

    );
}

export default VideoList;
