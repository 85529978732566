import { call, host } from "../../services/api";
import { serverErrorMessage, unauthorizedMessage } from "../../utils/message";
import { DELETE_VIDEO, REMOVE_ERROR, SET_DELETE, SET_ERROR, SET_LOADING, SET_SUCCESS, SET_VIDEOS } from "../type"

export const getVideos = ()=>{
    return async(dispatch)=>{
        dispatch({type: SET_LOADING});
        try{
            const response = await call("get", "api/videos");
            const data = response.data.videos;

            const transformResult = data.map((data)=>{
                const videoUrl = `${host}/api/videos/${data.video}`;
                return{
                    ...data,
                    key: data.id,
                    videoUrl: videoUrl
                }
            })

            dispatch({
                type: SET_VIDEOS,
                payload: transformResult,
            })

            dispatch({
                type: REMOVE_ERROR
            })
        }
        catch(error){
            const { status } = error.response;

            if (status === 401) {
                localStorage.removeItem("jwtToken");
                dispatch({
                    type: SET_ERROR,
                    payload: unauthorizedMessage,
                });
            }
  
           else {
                dispatch({
                    type: SET_ERROR,
                    payload: serverErrorMessage,
                });
            }

        }
        dispatch({type: SET_LOADING});
    }
}

export const deleteVideo = (id)=>{
    return async(dispatch)=>{
        dispatch({type: SET_DELETE, payload:false});
        dispatch({type: SET_LOADING});
        try{
            await call("delete", `api/videos/${id}`);
            dispatch({type: SET_DELETE, payload: true});
            dispatch({type: DELETE_VIDEO, payload: id});
            dispatch({
                type: REMOVE_ERROR
            })

        }
        catch(error){
            const { status } = error.response;

            if (status === 401) {
                localStorage.removeItem("jwtToken");
                dispatch({
                    type: SET_ERROR,
                    payload: unauthorizedMessage,
                });
            }
  
            else{
                dispatch({
                    type: SET_ERROR,
                    payload: serverErrorMessage,
                });
            }

        }
        setTimeout(() => {
            dispatch({ type: SET_DELETE, payload: false });
          }, 1);
          dispatch({ type: SET_LOADING });
    }
}

export const uploadVideo = (data)=>{
    return async(dispatch)=>{
        dispatch({type: SET_SUCCESS, payload: false});
        dispatch({type: SET_LOADING});
        try{
            await call("post","api/videos", data);
            dispatch({type: SET_SUCCESS, payload:true});
            dispatch({
                type: REMOVE_ERROR
            })
        }
        catch(error){
            const { status } = error.response;

            if (status === 401) {
                localStorage.removeItem("jwtToken");
                dispatch({
                    type: SET_ERROR,
                    payload: unauthorizedMessage,
                });
            }
  
            else{
                dispatch({
                    type: SET_ERROR,
                    payload: serverErrorMessage,
                });
            }

        }
        setTimeout(()=>{
            dispatch({type: SET_SUCCESS, payload: false})
        }, 1);
        dispatch({type: SET_LOADING});
    }
}