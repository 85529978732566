import { DELETE_PHOTO, SET_PHOTOS } from "../type"

const initialState ={
    photos:[]
}
const gallery = (state = initialState, action)=>{
    switch(action.type){
        case SET_PHOTOS:
            return{
                ...state,
                photos: action.payload
            }
        case DELETE_PHOTO:
            return{
                ...state,
                photos: state.photos.filter((photo)=> photo.id !== action.payload)
            }
        default:
            return state;
    }
}
export default gallery;