import { combineReducers } from 'redux';
import auth from './auth';
import error from "./error";
import status from './status';
import blog from "./blog";
import gallery from "./gallery";
import video from './video';

const reducers = combineReducers({
    auth,
    error, 
    status,
    blog,
    gallery,
    video,
});
export default reducers;