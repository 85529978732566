import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload, message } from 'antd';
import React from 'react';

const VideoUpload = ({ fileList, setFileList }) => {

  const beforeUpload = (file) => {
    const isVideo = file.type === "video/mp4";
    if (!isVideo) {
      message.error("Please upload video");
      return false;
    }
    return false;
  };

  const handleChange = ({ fileList }) => {
    setFileList([...fileList]);
    console.log("File Length:", fileList.length)
  };

  const props = {
    listType: 'picture',
    accept: "video/*",
    beforeUpload: { beforeUpload },
    onChange:(event) => handleChange(event),
  };

  return (
    <Upload {...props} fileList={fileList}>
      {fileList.length === 0 && <Button icon={<UploadOutlined />}>Upload Video</Button>}
    </Upload>
  );
}

export default VideoUpload;