import { call, host } from "../../services/api";
import { serverErrorMessage, unauthorizedMessage } from "../../utils/message";
import { DELETE_PHOTO, REMOVE_ERROR, SET_DELETE, SET_ERROR, SET_LOADING, SET_PHOTOS, SET_SUCCESS } from "../type"

export const getPhotos = () => {
    return async (dispatch) => {
        dispatch({ type: SET_LOADING });
        try {
            const response = await call("get", "api/gallery");
            const data = response.data.gallery;

            const transformResult = data.map((data) => {
                const photoUrl = `${host}/api/images/${data.photo}`;
                return {
                    ...data,
                    key: data.id,
                    photoUrl: photoUrl
                }
            });

            dispatch({
                type: SET_PHOTOS,
                payload: transformResult,
            });

            dispatch({
                type: REMOVE_ERROR
            })
        }
        catch (error) {
            const { status } = error.response;

            if (status === 401) {
                localStorage.removeItem("jwtToken");
                dispatch({
                    type: SET_ERROR,
                    payload: unauthorizedMessage,
                });
            }

            else {
                dispatch({
                    type: SET_ERROR,
                    payload: serverErrorMessage,
                });
            }
            console.log("Error:", error)
        }
        dispatch({ type: SET_LOADING });
    }
}

export const deletePhoto = (id) => {
    return async (dispatch) => {
        dispatch({ type: SET_DELETE, payload: false });
        dispatch({ type: SET_LOADING });
        try {
            await call("delete", `api/gallery/${id}`);
            dispatch({ type: SET_DELETE, payload: true });
            dispatch({ type: DELETE_PHOTO, payload: id });
            dispatch({
                type: REMOVE_ERROR
            })
        }
        catch (error) {
            const { status } = error.response;

            if (status === 401) {
                localStorage.removeItem("jwtToken");
                dispatch({
                    type: SET_ERROR,
                    payload: unauthorizedMessage,
                });
            }

            else {
                dispatch({
                    type: SET_ERROR,
                    payload: serverErrorMessage,
                });
            }

        }
        setTimeout(() => {
            dispatch({ type: SET_DELETE, payload: false });
        }, 1);
        dispatch({ type: SET_LOADING });
    }
}

export const uploadPhoto = (data) => {
    return async (dispatch) => {
        dispatch({ type: SET_SUCCESS, payload: false });
        dispatch({ type: SET_LOADING });
        try {
            await call("post", "api/gallery", data);

            dispatch({ type: SET_SUCCESS, payload: true });
            dispatch({
                type: REMOVE_ERROR,
            })
        }
        catch (error) {
            const { status } = error.response;

            if (status === 401) {
                localStorage.removeItem("jwtToken");
                dispatch({
                    type: SET_ERROR,
                    payload: unauthorizedMessage,
                });
            }

            else {
                dispatch({
                    type: SET_ERROR,
                    payload: serverErrorMessage,
                });
            }

        }
        setTimeout(() => {
            dispatch({ type: SET_SUCCESS, payload: false });
        }, 1);
        dispatch({ type: SET_LOADING });
    }
}