import { Box, Grid, Modal, Paper, Stack, TextField, Typography } from "@mui/material";
import ContainedButton from "../components/ContainedButton";
import TitlebarBelowImageList from "../components/TitlebarBelowImageList";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useEffect, useState } from "react";
import ImageUpload from "../components/ImageUpload";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector, useDispatch } from "react-redux";
import { getPhotos, uploadPhoto } from "../store/actions";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { successCreateMessage, successDeleteMessage } from "../utils/message";
import successNotify from "../components/SuccessNotify";
import errorNotify from "../components/ErrorNotify";


const ShowGallery = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [fileList, setFileList] = useState([]);

    const theme = useTheme();
    const downThanMd = useMediaQuery(theme.breakpoints.down('md'));
    const downThanLg = useMediaQuery(theme.breakpoints.down('lg'));

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: downThanLg ? (downThanMd ? "65vw" : "50vw") : "35vw",
        p: 4,
        borderRadius: "10px"
    };

    const photos = useSelector((state) => state.gallery.photos);
    const status = useSelector((state)=> state.status);
    const error = useSelector((state)=> state.error);

    const [title, setTitle] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        if (status.success) {
          successNotify(successCreateMessage);
        }
        return () => status.success;
      }, [status.success]);

      useEffect(()=>{
        if(status.delete){
            successNotify(successDeleteMessage);
        }
        return ()=> status.delete;
      },[status.delete])

      useEffect(() => {
        if (error.message !== null) {
          errorNotify(error.message);
        }
        return () => error.message;
      }, [error.message]);
    

    useEffect(() => {
        dispatch(getPhotos());
    }, [dispatch, status.success])

    const handleUpload = async()=>{
        if(fileList.length === 0){
           console.log("Please upload image");
        }else{
            const formData = new FormData();
            formData.append("title", title);
            formData.append("photo", fileList[0].originFileObj);
            await dispatch(uploadPhoto(formData));
            setFileList([]);
            setTitle("");
            handleClose();
        }
    }

    return (
        <>
         <ToastContainer />
            <Grid container spacing={2}>
                <Grid item lg={10} md={8} xs={12}>
                    <Box sx={{ width: "100%", bgcolor: 'white' }}>
                        <Typography
                            variant="h4"
                            component="h1"
                        >
                            <b>Gallery</b>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item lg={2} md={4} xs={12}>
                    <Box sx={{ width: "100%" }}>
                        <ContainedButton text="Upload Photo" startIcon={<CloudUploadIcon />} onClick={handleOpen} />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <TitlebarBelowImageList photos={photos} />
                </Grid>
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper sx={style}>
                    <Stack spacing={1}>
                        <Box>
                            <ImageUpload fileList={fileList} setFileList={setFileList} />
                        </Box>
                        <Box>
                            <TextField id="standard-basic" label="Title" variant="standard" sx={{ width: '100%' }} onChange={(e)=> setTitle(e.target.value)}/>
                        </Box>
                        <Box sx={{ width: "100%", pt: "20px" }}>
                            <ContainedButton text="Upload" onClick={handleUpload}/>
                        </Box>

                    </Stack>
                </Paper>
            </Modal>
        </>

    );
}
export default ShowGallery;