export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_USERS = "SET_USERS";
export const DELETE_USER = "DELETE_USER";

export const SET_LOADING = "SET_LOADING";
export const SET_SUCCESS = "SET_SUCCESS";
export const SET_DELETE = "SET_DELETE";

export const SET_ERROR = "SET_ERROR";
export const  REMOVE_ERROR= "REMOVE_ERROR";

export const SET_BLOG = "SET_BLOG";
export const SET_BLOGS = "SET_BLOGS";
export const DELETE_BLOG = "DELETE_BLOG";

export const SET_PHOTOS = "SET_PHOTOS";
export const DELETE_PHOTO = "DELETE_PHOTO";

export const SET_VIDEOS = "SET_VIDEOS";
export const DELETE_VIDEO = "DELETE_VIDEO";


